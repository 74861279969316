import React, { Component } from "react";

export default class BlogsImg extends Component {
  render() {
    // const theme = this.props.theme;
    return (
      <img
											src={require(`../../assests/images/instaaryacs.png`)}
											alt=""
										/> 
    );
  }
}
